<template>
  <div v-if="form" style="max-width: 420px">
    <div class="display-2 font-weight-bold mt-6 mb-4 ml-4">
      {{ form.username }}
    </div>

    <v-container>
      <v-form ref="form">
        <v-row no-gutters>
          <v-col>
            <UploadAvatar
              :image-source="user && user.avatar ? user.avatar.url : null"
              @change="onChangeImage"
            />
            <div
              class="ml-5 v-messages theme--light error--text"
              v-if="form.$getError('avatar')"
            >
              {{ form.$getError('avatar')[0] }}
            </div>
          </v-col>
          <v-col v-if="user">
            <v-row class="d-flex align-items-start flex-column">
              <div class="p-2"></div>
              <div class="mb-auto p-2"></div>
              <div class="p-2"></div>
            </v-row>

            <div class="d-flex flex-column mb-6">
              <v-card class="pa-2" flat>
                Impact points: {{ user.impact_points }}
              </v-card>
              <v-card class="pa-2" flat>
                Joined at {{ user.addedDate }}
              </v-card>
              <v-card class="pa-2" flat>
                Updated at {{ user.lastUpdate }}
              </v-card>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-4">
          <v-col>
            <label class="text-field-label">Username</label>
            <v-text-field
              flat
              solo
              class="mt-2"
              v-model="form.username"
              :error-messages="form.$getError('username')"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="pr-4">
            <label class="text-field-label">First Name</label>
            <v-text-field
              flat
              solo
              class="mt-2"
              v-model="form.first_name"
              :error-messages="form.$getError('first_name')"
            />
          </v-col>
          <v-col>
            <label class="text-field-label">Last Name</label>
            <v-text-field
              flat
              solo
              class="mt-2"
              v-model="form.last_name"
              :error-messages="form.$getError('first_name')"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <label class="text-field-label">Email</label>
            <v-text-field
              flat
              solo
              class="mt-2"
              v-model="form.email"
              :error-messages="form.$getError('email')"
            />

            <label class="text-field-label">Phone Number</label>
            <v-text-field
              flat
              solo
              required
              class="mt-2"
              v-model="form.raw_phone_number"
              :error-messages="form.$getError('phone_number')"
            />
            <template v-if="form.current_otp_code">
              <label class="text-field-label">Current OTP</label>
              <v-text-field
                flat
                solo
                class="mt-2"
                v-model="form.current_otp_code"
                disabled
              />
            </template>

            <label class="text-field-label">Organisation</label>
            <v-select
              flat
              solo
              v-model="form.organisation_id"
              :items="selectItems"
              item-text="name"
              item-value="id"
            ></v-select>

            <div v-if="user">
              <label class="text-field-label">Bio</label>
              <v-textarea
                no-resize
                flat
                solo
                readonly
                class="mt-2"
                rows="3"
                v-model="form.bio"
                :error-messages="form.$getError('bio')"
              />
            </div>

            <div class="mt-4 d-flex justify-space-between">
              <v-btn
                color="yellow"
                depressed
                class="px-6"
                height="40px"
                @click="submit"
                :loading="form.$busy"
                >Save</v-btn
              >

              <v-btn
                v-if="user"
                color="red"
                dark
                depressed
                class="px-6"
                height="40px"
                @click="showDialog = true"
                :loading="form.$busy"
                >Delete</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-dialog v-model="showDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Delete ?</v-card-title>

        <v-card-text
          >Are you sure you want to delete this account ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="deleteDialog = false" :loading="form.$busy"
            >Cancel</v-btn
          >
          <v-btn color="red" text @click="deleteUser" :loading="form.$busy"
            >Yes, Delete please</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Compressor from 'compressorjs'
import Form from '@/utils/form'
import UploadAvatar from './UploadAvatar.vue'

export default {
  name: 'UserForm',

  components: {
    UploadAvatar
  },

  data() {
    return {
      isLoading: false,
      imageChanged: false,
      showDialog: false,
      showCropperModal: false,
      form: null
    }
  },

  computed: {
    ...mapState({
      user: state => state.user.userDetails,
      organisations: state => state.organisation.list
    }),

    selectItems() {
      let noOrg = [
        {
          id: null,
          name: 'No Associated Organisation'
        }
      ]

      return noOrg.concat(this.organisations)
    }
  },

  methods: {
    ...mapActions({
      getUsers: 'user/getUsers',
      getUserDetails: 'user/getUserDetails',
      createUser: 'user/createUser',
      updateUser: 'user/updateUser',
      deleteUser: 'user/deleteUser',
      getOrganisations: 'organisation/getOrganisations'
    }),

    ...mapMutations({
      clearUserDetails: 'user/clearUserDetails',
      clearOrganisationList: 'organisation/clearOrganisationList',
      callSnackbar: 'callSnackbar'
    }),

    async getUser() {
      this.isLoading = true
      await this.getUserDetails(this.$route.params.id)
      this.form = new Form(this.user)
      this.isLoading = false
    },

    async submit() {
      this.form.$busy = true
      this.form.$clearErrors()

      if (!this.user) {
        await this.createUser(this.getFormData())
          .then(() => {
            this.form.$busy = false
            this.imageChanged = false
            this.callSnackbar({
              state: true,
              text: 'Successfully added new user',
              color: 'green'
            })
            this.$router.replace({
              name: 'users'
            })
          })
          .catch(err => {
            this.form.$busy = false
            if (err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }
          })
      } else {
        let formData = this.getFormData()

        formData.append('_method', 'put')

        await this.updateUser(formData)
          .then(() => {
            this.form.$busy = false
            this.imageChanged = false
            this.callSnackbar({
              state: true,
              text: 'Successfully update user',
              color: 'green'
            })
            this.$router.replace({
              name: 'users'
            })
          })
          .catch(err => {
            this.form.$busy = false
            if (err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }
          })
      }
    },

    async deleteUser() {
      this.form.$busy = true
      await this.user.delete().then(async () => {
        this.form.$busy = false
        this.showDialog = false
        this.callSnackbar({
          state: true,
          text: 'Successfully delete user',
          color: 'green'
        })
        await this.getUsers({ page: 1 })
        this.$router.replace({
          name: 'users'
        })
      })
    },

    getFormData() {
      let data = new FormData()

      if (this.imageChanged) {
        data.append('avatar', this.form.avatar)
      }

      if (this.form.first_name !== '' && this.form.first_name !== null) {
        data.append('first_name', this.form.first_name)
      }

      if (this.form.last_name !== '' && this.form.last_name !== null) {
        data.append('last_name', this.form.last_name)
      }

      if (this.form.email !== '' && this.form.email !== null) {
        data.append('email', this.form.email)
      }

      if (this.form.username !== '' && this.form.username !== null) {
        data.append('username', this.form.username)
      }

      data.append('phone_number', this.form.raw_phone_number)

      data.append('organisation_id', this.form.organisation_id ?? '')

      return data
    },

    onChangeImage(blob) {
      this.imageChanged = true
      this.form.$clearErrors()

      if (blob.size < 4096) {
        this.form.avatar = blob
      } else if (blob.size < 1000000) {
        let _this = this
        // eslint-disable-next-line
        new Compressor(blob, {
          quality: 0.2,
          success(result) {
            _this.form.avatar = result
          }
        })
      } else {
        this.form.$setErrors({ avatar: ['Image size is too large'] })
      }
    }
  },

  created() {
    this.getOrganisations({ type: 'all' })

    if (this.$route.params.id) {
      this.getUser()
    } else {
      this.form = new Form({
        first_name: '',
        last_name: '',
        email: '',
        raw_phone_number: '',
        username: '',
        avatar: '',
        organisation_id: ''
      })
    }
  },

  destroyed() {
    this.clearOrganisationList({ page: 1 })
    this.clearUserDetails()
  },

  watch: {
    $route() {
      this.getOrganisations()
      this.getUser()
    }
  }
}
</script>
